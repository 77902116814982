
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent ({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const goToApproveExportGroup = () => {
      router.push({ name: 'approve_export_group', params: {id: route.params.eppcm_id} })
    }

    const export_group = ref({} as any)

    const getPriceTiers = (type) => {
      store
        .dispatch(Actions.GET_PRICE_TIERS, frontHtml.value.id)
        .then(() => {
          // nextTick(() => {
          //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
          // })

          if (type == 'front') {
            price_tiers_front.value = store.getters.currentPriceTiers
            currentPriceTierFront.value = price_tiers_front.value[0]
          } else {
            price_tiers_back.value = store.getters.currentPriceTiers
            currentPriceTierBack.value = price_tiers_back.value[0]
          }

          query(type)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    store
      .dispatch(Actions.GET_EXPORT_GROUP, route.params.id)
      .then(() => {
        export_group.value = store.getters.currentExportGroup

        if (export_group.value.front_html_id) {
          store
            .dispatch(Actions.GET_HTML, export_group.value.front_html_id)
            .then(() => {
              frontHtml.value = store.getters.currentHtml
              getPriceTiers('front')
              
              // nextTick(() => {
              //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
              // })

              if (export_group.value.back_html_id) {
                store
                  .dispatch(Actions.GET_HTML, export_group.value.back_html_id)
                  .then(() => {
                    backHtml.value = store.getters.currentHtml
                    getPriceTiers('back')
                    // nextTick(() => {
                    //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
                    // })
                  })
                  .catch(() => {
                    // const [error] = Object.keys(store.getters.getErrors);
                    // Swal.fire({
                    //   text: store.getters.getErrors[error],
                    //   icon: "error",
                    //   buttonsStyling: false,
                    //   confirmButtonText: "Try again!",
                    //   customClass: {
                    //     confirmButton: "btn fw-bold btn-light-danger",
                    //   },
                    // });
                  })
              }
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        }
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const htmls = ref([] as any)

    store
      .dispatch(Actions.GET_HTMLS, {eppcm_id: route.params.eppcm_id})
      .then(() => {
        htmls.value = store.getters.currentHtmls
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const currentHtml = ref('front' as any)

    const setFrontHtml = () => {
      currentHtml.value = 'front'
      currentHtmlId.value = export_group.value.front_html_id
    }

    const setBackHtml = () => {
      currentHtml.value = 'back'
      currentHtmlId.value = export_group.value.back_html_id
    }

    const currentHtmlId = ref(41 as any)

    const frontHtml = ref({} as any)
    const backHtml = ref({} as any)

    const goToHtmlPrevie = (id) => {
      // router.push({ name: 'approve_export_group_detail_preview', params: {eppcm_id: route.params.eppcm_id, export_group_id: route.params.id, id: id} })
      const routeData = router.resolve({name: 'approve_export_group_detail_preview', params: {eppcm_id: route.params.eppcm_id, export_group_id: route.params.id, id: id}});
      // console.log(routeData.href)
      window.open(routeData.href, '_blank');
    }

    const currentPriceTierFront = ref('')
    currentPriceTierFront.value = '1'

    const currentPriceTierBack = ref('')
    currentPriceTierBack.value = '1'

    const price_tiers_front = ref([] as any)
    const price_tiers_back = ref([] as any)

    const onPriceTierChangeFront = (e) => {
      currentPriceTierFront.value = e.target.value
      query('front')
    }

    const onPriceTierChangeBack = (e) => {
      currentPriceTierBack.value = e.target.value
      query('back')
    }

    const query = (type) => {
      if (type == 'front') {
        store
          .dispatch(Actions.GET_HTML_PREVIEW, {id: frontHtml.value.id, params: {price_tier: currentPriceTierFront.value}})
          .then(() => {
            frontHtml.value.dir_url = store.getters.currentHtmlPreview
            // nextTick(() => {
            //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
            // })
          })
          .catch(() => {
            // const [error] = Object.keys(store.getters.getErrors);
            // Swal.fire({
            //   text: store.getters.getErrors[error],
            //   icon: "error",
            //   buttonsStyling: false,
            //   confirmButtonText: "Try again!",
            //   customClass: {
            //     confirmButton: "btn fw-bold btn-light-danger",
            //   },
            // });
          })
      } else {
        store
          .dispatch(Actions.GET_HTML_PREVIEW, {id: backHtml.value.id, params: {price_tier: currentPriceTierBack.value}})
          .then(() => {
            backHtml.value.dir_url = store.getters.currentHtmlPreview
            // nextTick(() => {
            //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
            // })
          })
          .catch(() => {
            // const [error] = Object.keys(store.getters.getErrors);
            // Swal.fire({
            //   text: store.getters.getErrors[error],
            //   icon: "error",
            //   buttonsStyling: false,
            //   confirmButtonText: "Try again!",
            //   customClass: {
            //     confirmButton: "btn fw-bold btn-light-danger",
            //   },
            // });
          })
      }
    }

    return {
      goToApproveExportGroup,
      export_group,
      htmls,
      setFrontHtml,
      setBackHtml,
      currentHtmlId,
      frontHtml,
      backHtml,
      goToHtmlPrevie,
      price_tiers_front,
      price_tiers_back,
      onPriceTierChangeFront,
      onPriceTierChangeBack
    }
  }
})
